@mixin font-uppercase {
  text-transform: uppercase;
  letter-spacing: 0.1rem;
}

@mixin heading-block-h1 {
  font-family: $theme-font-bold;
  color: $primary;

  display: inline-block;
  font-size: 2.2rem;
  margin-bottom: 0.2rem;

  @include media-breakpoint-up(sm) {
    font-size: 3.1rem;
    margin-bottom: 1rem;
  }
  @include media-breakpoint-up(md) {
    font-size: 3.1rem;
    margin-bottom: 1rem;
  }
  @include media-breakpoint-up(lg) {
    font-size: 3.2rem;
    margin-bottom: 1rem;
  }
  @include media-breakpoint-up(xl) {
    font-size: 3.5rem;
    margin-bottom: 1rem;
  }
}

@mixin heading-h2 {
  @include heading-block-h1;
}

@mixin heading-block-subtitle {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  @include font-uppercase;
}

@mixin jumbo-title {
  font-family: $theme-font-regular;
  font-size: 2.7rem;
  margin-bottom: 1rem;
  @include font-bold;

  @include media-breakpoint-up(md) {
    margin-bottom: 1.5rem;
    font-size: 4rem;
  }
  @include media-breakpoint-up(xl) {
    margin-bottom: 1.5rem;
    font-size: 4.5rem;
  }
}
