@mixin card-with-image-left {
  border-radius: 3rem 0 0 0;
}
@mixin card-with-image-right {
  border-radius: 0 3rem 0 0;
}
@mixin card-image-left {
  border-radius: 3rem 0 0 0;
}
@mixin card-image-right {
  border-radius: 0 3rem 0 0;
}

@mixin card-body {
  color: $font-color-default;
  padding: 1.5rem 2rem 1.5rem 2rem;
  @include media-breakpoint-up(md) {
    padding: 1.5rem 3rem 1.5rem 3rem;
  }
}
