$cloudinaryThemeCloudBaseUrl: 'https://res.cloudinary.com/difp9jq5j/image/upload/themes/carreisen-ostschweiz/';
@import 'styles/helpers/global_variables';

$ribbonBgColor: $signal;

.root {
  .ribbon {
    @include branded-button-font;

    font-size: 16px;
    padding: 11px 40px 11px 48px;
    float: left;
    text-align: center;
    text-decoration: none;
    color: $font-color-default;
    position: relative;
    background: $ribbonBgColor;
    min-width: 181px;
    width: auto;
    line-height: 22px;
    transition: all 0.2s ease-in-out;
    border-radius: 0 $btn-border-radius $btn-border-radius 0;

    &:hover {
      padding: 11px 40px 11px 52px;
    }

    @include media-breakpoint-up(sm) {
      font-size: 1.06rem;
    }
  }
}
