$cloudinaryThemeCloudBaseUrl: 'https://res.cloudinary.com/difp9jq5j/image/upload/themes/carreisen-ostschweiz/';
@import 'styles/helpers/global_variables';

.root {
  page-break-inside: avoid;

  .cardBody {
    @include card-body;
    //padding: 1.3rem 2rem 1.5rem 2rem;
    //@include media-breakpoint-up(md) {
    //  padding: 1.3rem 3rem 1.5rem 3rem;
    //}
  }

  @media print {
    .cardTitle {
      @include font-content-title;
    }
  }

  // @include print-avoid-break-inside;
}
