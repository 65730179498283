@mixin slider-buttons-wrap {
  //display: flex;
  //align-items: center;
  //position: absolute;
  //top: 50%;
  //transform: translateY(-50%);
  //left: 1.6rem;
  //height: 100%;
  @include print-hidden;
}

@mixin slider-dots-wrap {
  display: flex;
  list-style: none;
  justify-content: center;

  &.bottom {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: -37px;
  }

  &.below {
    margin-top: 10px;
  }
}

@mixin slider-dot-button {
  background-color: transparent;
  cursor: pointer;
  position: relative;
  padding: 0;
  outline: 0;
  border: 0;
  width: 65px;
  height: 30px;
  margin-right: 9px;
  margin-left: 9px;
  display: flex;
  align-items: center;

  &::after {
    width: 100%;
    height: 3px;
    border-radius: 2px;
    content: '';
  }

  &.light::after {
    background-color: $gray-200;
  }

  &.dark::after {
    background-color: $gray-600;
  }

  &.isSelected::after {
    background-color: $signal;
    opacity: 1;
  }
}
