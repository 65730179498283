@mixin contact-banner-title {
  @include readability-text-shadow;
  // @include font-bold;
  font-family: $theme-font-bold;

  position: absolute;
  z-index: 1;
  width: auto;
  top: 7rem;
  margin-left: 0.5rem;
  padding-right: 1.5rem;
  font-size: 2.3rem;

  @include media-breakpoint-up(sm) {
    top: 4rem;
    font-size: 3.8rem;
    text-align: right;
  }

  @include media-breakpoint-up(md) {
    top: 1rem;
    font-size: 4rem;
    text-align: right;
    left: 2rem;
  }

  @include media-breakpoint-up(lg) {
    top: 2rem;
    font-size: 4.5rem;
    text-align: left;
    left: 13rem;
  }

  @include media-breakpoint-up(xl) {
    top: 2.3rem;
    font-size: 4.5rem;
    text-align: left;
    left: 18rem;
  }

  @include media-breakpoint-up(xxl) {
    top: 2rem;
    font-size: 5rem;
    text-align: left;
    left: 20rem;
  }
}
