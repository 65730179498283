@mixin slider-dot-button {
  background-color: transparent;
  cursor: pointer;
  position: relative;
  padding: 0;
  outline: 0;
  border: 0;
  width: 72px;
  height: 30px;
  margin-right: 9px;
  margin-left: 9px;
  display: flex;
  align-items: center;

  &::after {
    width: 100%;
    height: 4px;
    border-radius: 9px;
    content: '';

    @include media-breakpoint-up(sm) {
      height: 9px;
    }
  }

  &.light::after {
    background-color: $gray-200;
    opacity: 0.7;
  }

  &.dark::after {
    background-color: $gray-600;
    opacity: 0.7;
  }

  &.isSelected::after {
    background-color: $signal;
    opacity: 1;
  }
}
