@mixin print-avoid-break-inside {
  @media print {
    break-inside: avoid;
  }
}

@mixin print-hidden {
  @media print {
    display: none;
  }
}