@mixin branded-list($bulletTop: 0.2rem) {
  padding-left: 0;
  list-style: none;

  li {
    padding-left: 1.5rem;
    position: relative;
    margin-bottom: 0.7rem;

    &::before {
      font-weight: normal;
      content: '\002B';
      color: inherit;
      font-family: $theme-font-bold;
      font-size: 1.2rem;
      line-height: 0.9rem;
      position: absolute;
      left: 0;
      top: $bulletTop;
    }
  }
}
